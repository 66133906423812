<template>
  <div>
  
    <b-card-code title="  الأفراد غير المسجلون">
      <!-- search input -->
      <b-row>
        <b-col   
        
            md="3" 
            >  
    <b-button v-if="!loading" variant="success" class="shadow mb-3" @click="triggerFileInput">  
      <span  class="mr-25 align-middle" > Import Excel File </span>  
      <!-- <span v-else class="mr-25 align-middle" > Loading ... </span>   -->
    </b-button>  
    <b-button v-else variant="success" class="shadow mb-3" disabled="true">  
      <span  class="mr-25 align-middle" >... Loading  </span>  
      
    </b-button>  
    <input  
      type="file"  
      ref="fileInput"  
      accept=".xls,.xlsx"  
      style="display: none"  
      @change="handleFileUpload"  
    />  
  
  </b-col> 
  <b-col cols="6" md="6"  ></b-col> 
        <b-col
            md="3" 
            >
          <b-button variant="purple" class="shadow mb-3">
            <vue-excel-xlsx
              ref="childComponent"
              :data="datatest"
              :columns="columns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
            </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
          <b-col
            cols="3"
            md="3"
            class="mb-md-0 mb-2"
          >
          <label>الجنس</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="gender"
            :options="genderOptions"
            class="w-100"
            :reduce="(val) => val.value"
           
          />

          </b-col>
          <b-col
            cols="3"
            md="3"
            class="mb-md-0 mb-2"
          >
          </b-col>

          <b-col
          cols="6" md="6"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <label>بحث حسب الاسم أو رقم الهاتف</label>
              <b-form-input
                v-model="search"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />

            </validation-provider>
          </b-col>

   </b-row>
    
      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد الأفراد</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-unRegistered',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deleteUser(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          
              </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import useList from "./List";
import * as XLSX from 'xlsx'; 

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,

  },
setup(){
  const today = new Date();
  const str = today.toISOString().split('T')[0];
  const {
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      gender,
      datatest,
      dateString
      

      // Extra Filters
    } = useList()
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      gender,
      datatest,
      dateString,
      str,
      today
    }

},

  data() {
    return {
      loading: false,
      genderOptions : [
      { label: 'ذكر', value: 1 },
      { label: 'أنثى', value: 2 },
      { label: ' غير محدد ', value: 0 },
    ],
      searchTerm: "",
      id: "",
      columns: [
       
       {
         label: "first_name",
         field: "first_name",
       },
       {
         label: "last_name",
         field: "last_name",
       },
        {
         label:"father_name",
         field: "father_name",
       },
       
        {
         label:"mother_name",
        field: "mother_name",
       },
        {
         label:"national_no",
        field : "national_no",
       },
        {
         label:"birth_date",
        field : "birth_date",
       },
       {
         label:"birth_place",
        field: "birth_place",
       },
        {
         label:"nationality",
        field: "nationality_id.name",
       },
       {
         label:"educational_status",
        field: "educational_status.name",
       },
       {
         label: "gender",
         field: "gender.name",
       },
       {
         label: "phone",
         field: "phone",
       }, {
         label: "family_status",
         field: "family_status_id.name",
       }, {
         label: "displacement_date",
         field: "displacement_date",
       }, {
         label: "martial_status",
         field: "martial_status_id.name",
       }, {
         label: "educational_attainment",
         field: "educational_attainment_id.name",
       }, {
         label: "current_work",
         field: "current_work_id.name",
       }, {
         label: "last_work",
         field: "last_work_id.name",
       },
       {
         label: "notes",
         field: "notes",
       },
     ],
       filename: `un-registered-persons ${this.str}`,
      
    };
  },

  created() {
 
  },

  methods: {

    triggerFileInput() {  
      this.$refs.fileInput.click();  
    },  
    handleFileUpload(event) {  
  const file = event.target.files[0];  

  console.log(file);  
  this.loading = true
  try {  
    // Convert file to FormData  
    const formData = new FormData();  
    formData.append('file', file);  
    
    // Log the contents of the FormData object  
    for (const pair of formData.entries()) {  
      console.log(`${pair[0]}: ${pair[1].name || pair[1]}`);  
    }  



    this.$http.post(`api/v1/import-un-registered-person`, formData).then((res) => {  
      this.loading = false
      this.refetchData(); 
      this.$refs.fileInput.value = null; // Clear the value  
// setTimeout(() => {  
//   this.$refs.fileInput.dispatchEvent(new Event('change')); // Manually trigger change  
// }, 0);  
   
      this.$swal({  
        title: "",  
        text: "تم تحميل الملف بنجاح",  
        icon: "success",  
        confirmButtonText: "موافق",  
        customClass: {  
          confirmButton: "btn btn-primary",  
        },  
        buttonsStyling: false,  
      });  
    });  
  }  
  catch (error) {  
    this.loading = false; // Stop loading on error  
    this.$swal({  
      text: `${error.response.data.message}`,  
      icon: 'error',  
      confirmButtonText: 'موافق',  
      customClass: {  
        confirmButton: 'btn btn-primary',  
      },  
      buttonsStyling: false,  
    });   
  }  
}  ,
    deleteUser(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/mobile-team/un-registered-persons/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.refetchData();
            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
          });
        }
      });
    },
    async download() {
      console.log(this.datatest)
      return new Promise((resolve, reject) => {
        
 this.$refs.childComponent.exportExcel();
      });
    },
  


  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}
.btn-success {
  color: white;
  
  margin-top: 25px;
}
span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>